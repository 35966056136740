import React from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";

type Props = {
  children: React.ReactNode;
  subText?: string;
  isSubTextAbove?: boolean;
  isCentered?: boolean;
};

export const SectionTitle: React.FC<Props> = ({
  children,
  subText,
  isSubTextAbove,
  isCentered,
}) => {
  return (
    <Fade left>
      <div className="py-12">
        {!!subText && isSubTextAbove && (
          <div>
            <h3
              className={`text-2xl md:text-3xl font-extrabold text-center text-maxima-secondary-400 ${
                isCentered && "md:text-center"
              }`}
            >
              {subText}
            </h3>
          </div>
        )}
        <div>
          <h2
            className={`text-4xl font-extrabold tracking-tight text-gray-300 lg:text-6xl text-center ${
              isCentered && "md:text-center"
            }`}
          >
            {children}
          </h2>
        </div>
        {!!subText && !isSubTextAbove && (
          <div>
            <h3
              className={`text-2xl md:text-3xl font-extrabold text-maxima-secondary-400 text-center ${
                isCentered && "md:text-center"
              }`}
            >
              {subText}
            </h3>
          </div>
        )}
      </div>
    </Fade>
  );
};
